html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #FF0000;
}

.page {
  margin: 0 20px 20px 20px;
}

.interactions {
  text-align: center;
}

.bottom-btn {
  margin-top: 20px;
}

.search {
  flex-grow: 1;
  margin-top: 20px;
}

.meme-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
  margin-top: 20px;
}

.grid-list {
  width: 100%;
  max-height: 320;
  flex-wrap: nowrap;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px ridge #2f62df;
}

.modal img{
  width: 100%;
  height: 100%;
  display: block;
}

.icon {
  font-size: 12px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggled {
  right: 13.5px !important;
}

.hexagon {
  position: fixed;
  width: 33px;
  height: 19.05px;
  background-color: #2f62df;
  margin: 9.53px 0;
  right: -35px;
  bottom: 11.5px;
  cursor: pointer;
  transition: right 0.5s;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 16.5px solid transparent;
  border-right: 16.5px solid transparent;
}

.hexagon:before {
  bottom: 100%;
  border-bottom: 9.53px solid #2f62df;
}

.hexagon:after {
  top: 100%;
  width: 0;
  border-top: 9.53px solid #2f62df;
}

.progress-bar {
  background: rgba(255, 255, 255, 0.14);
  height: 3px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
}

.progress {
  height: 3px;
  background: #2f62df;
}
